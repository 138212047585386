<template>
	<v-layout column px-3>
		<v-flex shrink text-xs-right>
			<w-search-input v-model="search" :placeholder="$t('actions.search')" />
			<w-btn
				v-if="canCreateManyApplicationVendors"
				:disabled="createManyApplicationVendorsDisabled"
				icon="add"
				:loading="creationLoading"
				small
				@click="createManyApplicationVendors()"
			>
				{{ $t('application.actions.create_many_application_vendors') }}
			</w-btn>
		</v-flex>
		<ApplicationVendorExpansionPanel
			v-if="params.length > 0"
			:loading="loading"
			:search="search"
			:params="params"
			:value="vendors"
			@select="selectRow"
			@unselect="unselectRow"
		/>
		<ApplicationVendorDataTable
			v-else
			:loading="loading"
			:search="search"
			:pagination="pagination"
			:value="vendors"
			@select="selectRow"
			@select-all="selectAllRows"
			@unselect="unselectRow"
			@unselect-all="unselectedAllRows"
			@update:pagination="onPaginationUpdated"
		/>
	</v-layout>
</template>

<script>
import ApplicationsModuleGuard from '@/mixins/ModulesGuards/Applications/ApplicationsModuleGuard'

import Vue from 'vue'

export default {
	name: 'ApplicationVendorAssigner',
	components: {
		ApplicationVendorDataTable: () => ({
			component: import('@/components/Applications/ApplicationVendorDataTable')
		}),
		ApplicationVendorExpansionPanel: () => ({
			component: import('@/components/Applications/ApplicationVendorExpansionPanel')
		})
	},
	mixins: [ApplicationsModuleGuard],
	props: {
		applicationId: {
			default: null,
			required: false,
			type: Number
		},
		applicationName: {
			default: '',
			required: false,
			type: String
		},
		params: {
			default: () => [],
			required: false,
			type: Array
		},
		subscriptionId: {
			default: null,
			required: false,
			type: Number
		}
	},
	data: function () {
		return {
			createManyApplicationVendorsDisabled: true,
			createManyTimeout: null,
			creationLoading: false,
			loading: false,
			search: '',
			pagination: {
				descending: false,
				page: 1,
				rowsPerPage: 5,
				sortBy: 'company',
				totalItems: 0
			},
			vendors: []
		}
	},
	computed: {
		canCreateManyApplicationVendors: function () {
			return this.params.every(param => {
				return param.auto
			})
		}
	},
	watch: {
		subscriptionId: {
			handler: 'load'
		},
		search: {
			handler: function () {
				clearTimeout(this.searchTimeout)
				this.searchTimeout = setTimeout(() => {
					this.loadVendors()
				}, 500)
			}
		}
	},
	mounted: function () {
		this.load()
	},
	methods: {
		checkIfCanCreateManyApplicationVendors: function () {
			this.service.listVendors(this.accountingFirmId, this.subscriptionId).then(vendors => {
				this.createManyApplicationVendorsDisabled = vendors.length == 0 || vendors.every(vendor => vendor.is_active)
			})
		},
		createManyApplicationVendors: function () {
			this.creationLoading = true
			this.service
				.createApplicationVendors(this.accountingFirmId, this.subscriptionId)
				.then(() => {
					this.createManyApplicationVendorsDisabled = true
					this.appEventBus.emit(
						this.appEvents.SNACKBAR_SUCCESS,
						this.$t('application.events.create_many_application_vendors_launched', {
							application: this.applicationName
						})
					)
				})
				.finally(() => {
					this.creationLoading = false
				})
		},
		getModuleEventsActionsMapping: function () {
			return [
				{ event: this.events.APPLICATION_VENDOR_CREATED, action: this.onApplicationVendorCreated },
				{ event: this.events.APPLICATION_VENDOR_DELETED, action: this.onApplicationVendorDeleted }
			]
		},
		load: function () {
			this.checkIfCanCreateManyApplicationVendors()
			this.loadVendors()
		},
		loadVendors: function () {
			if (!this.subscriptionId) {
				this.vendors.splice(0, this.vendors.length)
				return Promise.resolve()
			}

			this.loading = true
			let promise = Promise.resolve()
			if (this.params.length > 0 || this.pagination.rowsPerPage === -1) {
				promise = this.service.listVendors(this.accountingFirmId, this.subscriptionId, {search: this.search})
			} else {
				promise = this.service
					.listVendors(this.accountingFirmId, this.subscriptionId, {
						page: this.pagination.page ?? 1,
						rowsPerPage: this.pagination.rowsPerPage ?? 5,
						search: this.search,
					})
					.then(({ data: vendors, pagination }) => {
						this.pagination.page = pagination.currentPage
						this.pagination.rowsPerPage = pagination.perPage
						this.pagination.totalItems = pagination.total
						return vendors
					})
			}

			promise
				.then(vendors => {
					this.vendors = vendors.map(this.parseVendor)
				})
				.finally(() => {
					this.loading = false
					this.$emit('loaded')
				})
		},
		onApplicationVendorCreated: function () {
			if (this.createManyTimeout) {
				clearTimeout(this.createManyTimeout)
			}

			this.createManyTimeout = setTimeout(
				function () {
					this.checkIfCanCreateManyApplicationVendors()
				}.bind(this),
				3000
			)
		},
		onApplicationVendorDeleted: function () {
			this.createManyApplicationVendorsDisabled = false
		},
		onPaginationUpdated: function (paginationData) {
			this.loading = true
			this.pagination = paginationData

			this.service
				.listVendors(this.accountingFirmId, this.subscriptionId, {
					page: this.pagination.page ?? 1,
					rowsPerPage: this.pagination.rowsPerPage ?? 5,
					search: this.search,
				})
				.then(response => {
					let vendors = []
					if (Array.isArray(response)) {
						this.pagination.page = 1
						this.pagination.rowsPerPage = -1
						vendors = response
					} else {
						const pagination = response.pagination
						this.pagination.page = pagination.currentPage
						this.pagination.rowsPerPage = pagination.perPage
						this.pagination.totalItems = pagination.total
						vendors = response.data
					}
					this.vendors = vendors.map(this.parseVendor)
				})
				.finally(() => {
					this.loading = false
				})
		},
		parseVendor: function (vendor) {
			return {
				application_vendor_id: vendor.application_vendor_id,
				client_code: vendor.client_code,
				company: vendor.name,
				loading: false,
				params: vendor.params ?? {},
				selected: vendor.is_active,
				siret: vendor.siret,
				vendor_id: vendor.id
			}
		},
		selectAllRows: function () {
			const promises = this.vendors.map(vendor => {
				let result = Promise.resolve()
				if (!vendor.application_vendor_id) {
					result = this.selectRow(vendor, {}, true)
				}
				return result
			})

			return Promise.allSettled(promises).then(() => {
				this.appEventBus.emit(
					this.appEvents.SNACKBAR_SUCCESS,
					this.$t('application.events.application_vendors_created', {
						application: this.applicationName
					})
				)
			})
		},
		selectRow: function (row, params = {}, mute = false) {
			row.loading = true
			return this.service
				.setUpApplication(this.accountingFirmId, row.vendor_id, this.applicationId, row.application_vendor_id, {
					params,
					parent_subscription_id: this.subscriptionId
				})
				.then(response => {
					const isUpdate = !!row.application_vendor_id
					row.application_vendor_id = response.id
					if (response.params) {
						const entries = Object.entries(response.params)

						for (const [key, value] of entries) {
							if (row.params.hasOwnProperty(key)) {
								row.params[key] = value
							} else {
								Vue.set(row.params, key, value)
							}
						}
					}
					row.params = response.params
					row.selected = true

					if (isUpdate) {
						this.eventBus.emit(this.events.APPLICATION_VENDOR_UPDATED, row)
						if (!mute) {
							this.appEventBus.emit(
								this.appEvents.SNACKBAR_SUCCESS,
								this.$t('application.events.application_vendor_updated', {
									application: this.applicationName,
									company: row.company
								})
							)
						}
					} else {
						this.eventBus.emit(this.events.APPLICATION_VENDOR_CREATED, row)
						if (!mute) {
							this.appEventBus.emit(
								this.appEvents.SNACKBAR_SUCCESS,
								this.$t('application.events.application_vendor_created', {
									application: this.applicationName,
									company: row.company
								})
							)
						}
					}
				})
				.finally(() => {
					row.loading = false
				})
		},
		unselectedAllRows: function () {
			const promises = this.vendors.map(vendor => {
				let result = Promise.resolve()
				if (vendor.application_vendor_id) {
					result = this.unselectRow(vendor, true)
				}
				return result
			})

			return Promise.allSettled(promises).then(() => {
				this.appEventBus.emit(
					this.appEvents.SNACKBAR_SUCCESS,
					this.$t('application.events.application_vendors_deleted', {
						application: this.applicationName
					})
				)
			})
		},
		unselectRow: function (row, mute = false) {
			row.loading = true
			return this.service
				.unsetApplication(this.accountingFirmId, row.vendor_id, this.applicationId, row.application_vendor_id)
				.then(() => {
					this.eventBus.emit(this.events.APPLICATION_VENDOR_DELETED)
					row.application_vendor_id = null
					row.selected = false

					if (!mute) {
						this.appEventBus.emit(
							this.appEvents.SNACKBAR_SUCCESS,
							this.$t('application.events.application_vendor_deleted', {
								application: this.applicationName,
								company: row.company
							})
						)
					}
				})
				.finally(() => {
					row.loading = false
				})
		}
	}
}
</script>